import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { QRCodeSVG } from 'qrcode.react';
import './ListarConjuntoFichas.css';

Modal.setAppElement('#root');

function ListarConjuntosFichas({ atualizar }) {
  const [conjuntos, setConjuntos] = useState([]);
  const [erro, setErro] = useState('');
  const [modalAberto, setModalAberto] = useState(false);
  const [fichasDetalhes, setFichasDetalhes] = useState(null);
  const [conjuntoId, setConjuntoId] = useState(null);

  // Função para buscar os conjuntos de fichas
  const fetchConjuntos = async () => {
    try {
      const response = await fetch('https://bugio.app:3000/bugio/listarconjuntosfichas');
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Erro ao buscar os conjuntos de fichas');
      }

      setConjuntos(data.conjuntos);
    } catch (error) {
      setErro(error.message);
      console.error(error);
    }
  };

  

  // Função para buscar as fichas de um conjunto específico
  const fetchFichasDetalhes = async (id) => {
    try {
      const response = await fetch(`https://bugio.app:3000/bugio/listarconjuntoficha/${id}`);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Erro ao buscar as fichas do conjunto');
      }

      const fichasOrdenadas = data.conjunto.fichas.sort((a, b) => {
        if (a.validada && !b.validada) return -1; // Validadas primeiro
        if (!a.validada && b.validada) return 1;  // Não validadas depois
        return 0;
      });

      setFichasDetalhes(fichasOrdenadas);
    } catch (error) {
      setErro(error.message);
      console.error(error);
    }
  };

  // Chama fetchConjuntos sempre que a prop 'atualizar' mudar
  useEffect(() => {
    fetchConjuntos();
  }, [atualizar]);

  const abrirModal = (id) => {
    fetchFichasDetalhes(id);
    setConjuntoId(id); // Armazena o ID do conjunto
    setModalAberto(true);
  };

  const fecharModal = () => {
    setModalAberto(false);
    setFichasDetalhes(null);
  };

  const imprimirConjunto = async (conjuntoId) => {
    try {
      const response = await fetch(`https://bugio.app:3000/bugio/imprimirfichas/${conjuntoId}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao enviar solicitação de impressão.');
      }
  
      alert('Solicitação de impressão enviada com sucesso!');
    } catch (error) {
      console.error('Erro ao solicitar impressão:', error);
      alert(`Erro ao solicitar impressão: ${error.message}`);
    }
  };
  


  return (
    <div>
      <div className="fichas-card">
        <h2>Lista de Conjuntos de Fichas</h2>
        {erro && <div className="error-message">{erro}</div>}
        <div className="fichas-list">
          {conjuntos.map((conjunto) => (
            <div
              key={conjunto._id}
              className="fichas-card-item"
              onClick={() => abrirModal(conjunto._id)} // Passa o ID para a função
            >
              <div className="descricao-e-data">
                <h3>{conjunto.descricao}</h3>
                <p className="data-criacao">
                  {new Date(conjunto.datacriacao).toLocaleString()}
                </p>
              </div>
              <p><strong>Locais:</strong> {conjunto.locais.join(', ')}</p>
              <p><strong>Total de Fichas:</strong> {conjunto.totalfichas}</p>
              <p><strong>Fichas validadas:</strong> {conjunto.fichasvalidadas}</p>
              <p><strong>Data de Validade:</strong> {new Date(conjunto.datahoravalidade).toLocaleString()}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={modalAberto} onRequestClose={fecharModal} contentLabel="Detalhes das Fichas">
        <div className="modal-header">
        <button
          className="botao-imprimir"
          onClick={() => imprimirConjunto(conjuntoId)}
        >
          Imprimir Conjunto
        </button>
        </div>
        <h2>Detalhes das Fichas</h2>
        {fichasDetalhes ? (
            <div className="fichas-detalhes-list">
            {fichasDetalhes.map((ficha) => (
                <div
                key={ficha._id}
                className={`ficha-card ${ficha.validada ? 'validada' : 'nao-validada'}`}
                >
                <h4>Ficha #{ficha.numero}</h4>
                <p><strong>Validada:</strong> {ficha.validada ? 'Sim' : 'Não'}</p>
                {ficha.validada && (
                    <div>
                    <p><strong>Produto:</strong> {ficha.produto.nome} {ficha.produto.quantidade} {ficha.produto.unidade}</p>
                    <p><strong>Validado em:</strong> {new Date(ficha.validadoem).toLocaleString()}</p>
                    <p><strong>Validado por:</strong> {ficha.validadopor.nome}</p>
                    </div>
                )}
                <div className="qr-code-container">
                    <QRCodeSVG value={ficha._id} size={128} />
                </div>
                </div>
            ))}
            </div>
        ) : (
            <p>Carregando os detalhes...</p>
        )}
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
    </div>
  );
}

export default ListarConjuntosFichas;
