import React, { useState, useEffect } from 'react';
import './Pedidos.css';
import WebSocketComponent from './WebSocketComponent';
import { Button, Dialog } from '@mui/material';
import axios from 'axios';

function PedidosListCard({ localSelecionado, handleLocalSelecionado }) {
  const [pedidos, setPedidos] = useState({ recebidos: [], emPreparacao: [] });
  const [modalOpen, setModalOpen] = useState(false);
  const [pedidoSelecionado, setPedidoSelecionado] = useState(null);
  const [modalDetalhesOpen, setModalDetalhesOpen] = useState(false);

  useEffect(() => {
    const fetchPedidos = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/app/listarpedidoscozinha');
        if (response.ok) {
          const data = await response.json();
          console.log("Pedidos recebidos", data); // Log dos dados para verificação

          // Agrupar pedidos em 'recebidos' e 'emPreparacao'
          const pedidosFiltrados = data.reduce(
            (acc, pedido) => {
              if (pedido.status === 'Pedido realizado' && (localSelecionado === 'Todos' || pedido.local === localSelecionado)) {
                acc.recebidos.push(pedido);
              } else if (pedido.status === 'Em preparação' && (localSelecionado === 'Todos' || pedido.local === localSelecionado)) {
                acc.emPreparacao.push(pedido);
              }
              return acc;
            },
            { recebidos: [], emPreparacao: [] }
          );
          setPedidos(pedidosFiltrados);
        } else {
          console.error('Erro ao obter os pedidos da cozinha:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao obter os pedidos da cozinha:', error);
      }
    };

    fetchPedidos();
  }, [localSelecionado]);

  const handlePedidoClick = (pedido) => {
    setPedidoSelecionado(pedido);
    setModalDetalhesOpen(true);
  };

  const handleCloseModal = () => {
    setPedidoSelecionado(null);
    setModalOpen(false);
    setModalDetalhesOpen(false);
    handleRefreshPedidos(); // Atualiza a lista de pedidos quando a janela de detalhes é fechada
  };

  const handleRefreshPedidos = async () => {
    try {
      const response = await fetch('https://bugio.app:3000/bugio/app/listarpedidoscozinha');
      if (response.ok) {
        const data = await response.json();
        const pedidosFiltrados = data.reduce(
          (acc, pedido) => {
            if (pedido.status === 'Pedido realizado' && (localSelecionado === 'Todos' || pedido.local === localSelecionado)) {
              acc.recebidos.push(pedido);
            } else if (pedido.status === 'Em preparação' && (localSelecionado === 'Todos' || pedido.local === localSelecionado)) {
              acc.emPreparacao.push(pedido);
            }
            return acc;
          },
          { recebidos: [], emPreparacao: [] }
        );
        setPedidos(pedidosFiltrados);
      } else {
        console.error('Erro ao obter os pedidos da cozinha:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao obter os pedidos da cozinha:', error);
    }
  };

  const handleCancelarPedido = async () => {
    const confirmCancel = window.confirm("Você tem certeza que deseja cancelar este pedido? O cancelamento gerará reembolso automaticamente para o cliente!");
    if (confirmCancel) {
      try {
        // Requisição para cancelar o pedido
        const response = await axios.put(`https://bugio.app:3000/bugio/app/cancelar-pedido/${pedidoSelecionado._id}`, { responsavelEstorno: localStorage.getItem('userId') });
        if (response.status === 200) {
          alert("Pedido cancelado com sucesso!");
          // Após o cancelamento do pedido, faça um novo fetch dos pedidos
          handleRefreshPedidos();
        } else {
          console.error('Erro ao cancelar o pedido:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao cancelar o pedido:', error);
      }
    } else {
      console.log("Cancelamento do cancelamento ;-)");
    }
  };

  const handlePrepararPedido = async () => {
    try {
      // Requisição para atualizar o status do pedido para "Em preparação"
      const response = await axios.put(`https://bugio.app:3000/bugio/app/atualizar-status/${pedidoSelecionado._id}`);
      if (response.status === 200) {
        alert("Status alterado para 'Em preparação'!");
        // Após a atualização do status, faça um novo fetch dos pedidos
        handleRefreshPedidos();
      } else {
        console.error('Erro ao atualizar o status do pedido:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao atualizar o status do pedido:', error);
    }
  };

  const handlePedidoPronto = async () => {
    try {
      // Requisição para atualizar o status do pedido para "Pronto"
      const response = await axios.put(`https://bugio.app:3000/bugio/app/atualizar-status/${pedidoSelecionado._id}`);
      if (response.status === 200) {
        alert("Status alterado para 'Pronto'!");
        // Após a atualização do status, faça um novo fetch dos pedidos
        handleRefreshPedidos();
      } else {
        console.error('Erro ao atualizar o status do pedido:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao atualizar o status do pedido:', error);
    }
  };

  return (
    <div className="pedidos-list-card">
      {localSelecionado !== "Todos" && (
        <WebSocketComponent local={localSelecionado} handleRefreshPedidos={handleRefreshPedidos} />
      )}

      <h1>{localSelecionado}</h1>
      <h2>Pedidos Recebidos</h2>
  
      <div className="pedido-list">
        {pedidos.recebidos.map((pedido) => {
          const timestamp = new Date(pedido.createdAt).toLocaleString();
          return (
            <div key={pedido._id} className="pedido-card" onClick={() => handlePedidoClick(pedido)}>
              <span className="pedido-nome">#{pedido.numeroPedido}</span>
              <span className="pedido-timestamp">{timestamp}</span>
            </div>
          );
        })}
        {pedidos.recebidos.length === 0 && (
          <div className="no-pedidos-message">Nenhum pedido encontrado.</div>
        )}
      </div>

      <h2>Em Preparação</h2>
      <div className="pedido-list">
        {pedidos.emPreparacao.map((pedido) => {
          const timestamp = new Date(pedido.createdAt).toLocaleString();
          return (
            <div key={pedido._id} className="pedido-card" onClick={() => handlePedidoClick(pedido)}>
              <span className="pedido-nome">#{pedido.numeroPedido}</span>
              <span className="pedido-timestamp">{timestamp}</span>
            </div>
          );
        })}
        {pedidos.emPreparacao.length === 0 && (
          <div className="no-pedidos-message">Nenhum pedido em preparação encontrado.</div>
        )}
      </div>

      <Dialog open={modalDetalhesOpen} onClose={handleCloseModal}>
        {modalDetalhesOpen && pedidoSelecionado && (
          <div className="modal-pedidos" onClick={handleCloseModal}>
            <div className="modal-content">
              <div className="pedido-info">
                <center>
                  <h2><strong>#{pedidoSelecionado.numeroPedido}</strong></h2>
                  <p>{new Date(pedidoSelecionado.createdAt).toLocaleString()}</p>
                  <p className="nome-produto">{pedidoSelecionado.produto.nome}</p>
                  <p><strong>Cliente:</strong> {pedidoSelecionado.cliente}</p>
                </center>
              </div>
              <div className="pedido-buttons">
                <Button variant="contained" color="error" onClick={handleCancelarPedido}>Cancelar Pedido</Button>
                {pedidoSelecionado.status === 'Pedido realizado' && (
                  <Button variant="contained" color="success" onClick={handlePrepararPedido}>Preparar Pedido</Button>
                )}
                {pedidoSelecionado.status === 'Em preparação' && (
                  <Button variant="contained" color="success" onClick={handlePedidoPronto}>Pedido Pronto</Button>
                )}
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default PedidosListCard;
