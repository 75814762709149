import React, { useState } from 'react';
import './ConsumoArtistas.css';
import Header from './Header';
import Footer from './Footer';
import CadastroConsumo from './CadastroConsumo';
import CartoesConsumo from './CartoesConsumo';
import ConjuntoFichas from './ConjuntoFichas';
import ListarConjuntoFichas from './ListarConjuntoFichas';

function ConsumoArtistas() {
  const [conjuntosAtualizados, setConjuntosAtualizados] = useState(false);
  const [atualizarCartoes, setAtualizarCartoes] = useState(false);

  const atualizarConjuntos = () => {
    setConjuntosAtualizados(!conjuntosAtualizados);
  };

  return (
    <div className="consumo-page">
      <Header />
      <div className="consumo-content">
        <div className="consumo-column">
          <CadastroConsumo atualizarCartoes={() => setAtualizarCartoes(!atualizarCartoes)} />
        </div>
        <div className="consumo-column">
          <CartoesConsumo atualizarCartoes={atualizarCartoes} />
        </div>
        <div className="consumo-column">
          <ConjuntoFichas atualizarConjuntos={atualizarConjuntos} />
          
        </div>
        <div className="consumo-column">
          <ListarConjuntoFichas atualizar={conjuntosAtualizados} />
          
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default ConsumoArtistas;
