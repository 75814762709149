import React, { useState, useEffect } from 'react';
import { FaTrash, FaBeer, FaPlus } from 'react-icons/fa'; // Importar ícones

import './EscalasComponent.css';

function EscalasComponent() {
  const [data, setData] = useState('');
  const [localidades, setLocalidades] = useState([]);
  const [selectedLocalidade, setSelectedLocalidade] = useState('');
  const [funcionarios, setFuncionarios] = useState([]);
  const [filteredFuncionarios, setFilteredFuncionarios] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [thirdModalOpen, setThirdModalOpen] = useState(false); // Novo estado para o terceiro modal
  const [selectedFuncionario, setSelectedFuncionario] = useState(null);
  const [horaInicio, setHoraInicio] = useState('');
  const [cargaHoraria, setCargaHoraria] = useState('');
  const [escalas, setEscalas] = useState([]);
  const [produtos, setProdutos] = useState([]); // Novo estado para produtos
  const [searchProdutoTerm, setSearchProdutoTerm] = useState('');
  const [fourthModalOpen, setFourthModalOpen] = useState(false);
  const [consumoInfo, setConsumoInfo] = useState([]);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
const [quantityModalOpen, setQuantityModalOpen] = useState(false);
const [selectedProduto, setSelectedProduto] = useState(null);
const [quantidade, setQuantidade] = useState(1);

const handleProductLongPress = (produto) => {
  setSelectedProduto(produto);
  setQuantityModalOpen(true);
};

const handleMouseDown = (produto) => {
  const timeout = setTimeout(() => handleProductLongPress(produto), 800); // Clique longo após 800ms
  setLongPressTimeout(timeout);
};

const handleMouseUpOrLeave = () => {
  clearTimeout(longPressTimeout);
  setLongPressTimeout(null);
};

const handleRegistrarQuantidade = () => {
  if (selectedFuncionario && selectedProduto && quantidade > 0) {
    const payload = {
      data,
      local: selectedLocalidade,
      produtoId: selectedProduto._id,
      funcionarioId: selectedFuncionario.id,
      quantidade,
    };

    fetch('https://bugio.app:3000/escalas/adicionar-consumo', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Consumo registrado:', data);
        setQuantityModalOpen(false);
        setQuantidade(1); // Reset quantidade
      })
      .catch((error) => console.error('Erro ao registrar consumo:', error));
  }
};

  useEffect(() => {
    fetch('https://bugio.app:3000/bugio/cardapio/listarlocais')
      .then(response => response.json())
      .then(data => setLocalidades(data.map(localidade => localidade.nome)))
      .catch(error => console.error('Erro ao buscar localidades: ', error));
  }, []);
  

  const handleDateChange = (e) => setData(e.target.value);
  const handleLocalidadeChange = (e) => setSelectedLocalidade(e.target.value);

  const handleAddFuncionario = () => {
    setModalOpen(true);
    fetch('https://bugio.app:3000/escalas/listar-funcionarios')
      .then(response => response.json())
      .then(data => {
        setFuncionarios(data);
        setFilteredFuncionarios(data);
      })
      .catch(error => console.error('Erro ao buscar funcionários: ', error));
  };

  const handleDeleteFuncionario = (funcionarioId) => {
    const confirmed = window.confirm("Tem certeza de que deseja remover este funcionário? AÇÃO IRREVERSÍVEL! O consumo será excluído junto.");
    if (confirmed) {
      fetch('https://bugio.app:3000/escalas/remove-funcionario', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idUsuario: funcionarioId,
          data: data,
          local: selectedLocalidade,
        }),
      })
        .then(response => {
          if (response.ok) {
            console.log('Funcionário removido com sucesso');
            // Atualize a lista de escalas removendo o funcionário deletado
            setEscalas(prevEscalas => prevEscalas.filter(func => func.id !== funcionarioId));
          } else {
            console.error('Erro ao remover funcionário');
          }
        })
        .catch(error => console.error('Erro ao tentar remover funcionário:', error));
    }
  };
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setFilteredFuncionarios(funcionarios);
    } else {
      setFilteredFuncionarios(
        funcionarios.filter(funcionario =>
          funcionario.nome.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSearchTerm('');
    setFilteredFuncionarios(funcionarios);
  };

  const handleFuncionarioClick = (funcionario) => {
    setSelectedFuncionario(funcionario);
    setSecondModalOpen(true);
  };

  const closeSecondModal = () => {
    setSecondModalOpen(false);
    setHoraInicio('');
    setCargaHoraria('');
  };

  const handleSave = () => {
    const payload = {
      idUsuario: selectedFuncionario._id,
      data,
      local: selectedLocalidade,
      horaInicio,
      cargaHoraria
    };
  
    fetch('https://bugio.app:3000/escalas/add-funcionario', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Funcionário adicionado:', data);
        closeSecondModal();
        closeModal();
        handleSearchEscala();
      })
      .catch(error => alert('Erro ao adicionar funcionário: ' + error.message));
  };
  

  const handleSearchEscala = () => {
    fetch(`https://bugio.app:3000/escalas/${data}/${selectedLocalidade}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.usuarios) {
          const escalasData = data.usuarios.map(usuario => ({
            nome: usuario.nomeUsuario,
            id: usuario._id,
            horaInicio: usuario.horaInicio,
          }));
          setEscalas(escalasData);
        } else {
          setEscalas([]);
        }
      })
      .catch(error => console.error('Erro ao buscar a escala: ', error));
  };

  const handleProductSearchChange = (e) => {
    setSearchProdutoTerm(e.target.value);
  };

  const fetchProdutos = () => {
    fetch(`https://bugio.app:3000/listarprodutosporlocal/${selectedLocalidade}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data.produtos)) {
          setProdutos(data.produtos);
        } else {
          console.error('Os dados retornados não são um array:', data);
        }
      })
      .catch(error => console.error('Erro ao buscar produtos: ', error));
  };

  const handleFuncionarioBeerClick = (funcionario) => {
    setSelectedFuncionario(funcionario);
    setThirdModalOpen(true);
    fetchProdutos();
  };

  const closeThirdModal = () => {
    setThirdModalOpen(false);
    setSearchProdutoTerm('');
  };

  const handleProductClick = (produto, quantidade) => {
    if (!quantidade || quantidade <= 0) {
        alert("Por favor, insira uma quantidade válida.");
        return;
    }

    if (selectedFuncionario) {
        const payload = {
            data,
            local: selectedLocalidade,
            produtoId: produto._id,
            funcionarioId: selectedFuncionario.id,
            quantidade, // Adicionando a quantidade ao payload
        };

        // Mensagem de confirmação detalhada
        const confirmationMessage = `
            Você está prestes a registrar um consumo com as seguintes informações:
            
            - Produto: ${produto.nome}
            - Quantidade: ${quantidade}
            - Funcionário: ${selectedFuncionario.nome}

            Deseja continuar?
        `;

        const userConfirmed = window.confirm(confirmationMessage);

        if (userConfirmed) {
            console.log("Funcionário selecionado:", selectedFuncionario);

            fetch('https://bugio.app:3000/escalas/adicionar-consumo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Consumo registrado:', data);
                    closeThirdModal();
                })
                .catch((error) => console.error('Erro ao registrar consumo:', error));
        } else {
            console.log('Ação cancelada pelo usuário.');
        }
    } else {
        console.error('Nenhum funcionário selecionado.');
    }
};




const sortedEscalas = [...escalas].sort((a, b) => {
    if (a.horaInicio < b.horaInicio) return -1;
    if (a.horaInicio > b.horaInicio) return 1;
    return 0;
  });
  
  const handleViewConsumo = (funcionario) => {
    setSelectedFuncionario(funcionario);
  
    const queryParams = new URLSearchParams({
      data: data,
      local: selectedLocalidade,
      funcionarioId: funcionario.id
    });
  
    fetch(`https://bugio.app:3000/escalas/listar-consumo?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setConsumoInfo(data);
        } else {
          console.error('Os dados retornados não são um array:', data);
          setConsumoInfo([]); // Define como array vazio em caso de erro
        }
        setFourthModalOpen(true);
      })
      .catch(error => {
        console.error('Erro ao buscar consumo:', error);
        setConsumoInfo([]); // Define como array vazio em caso de erro
      });
  };
  


  return (
    <div className="escalas-component">
      <h2 className="escalas-title">Montar Escalas</h2>
      <div className="escalas-date-picker">
        <input
          type="date"
          id="data"
          className="escalas-date-input"
          value={data}
          onChange={handleDateChange}
        />
      </div>
      <div className="escalas-localidade-picker">
        <select
          id="localidade"
          className="escalas-select-input"
          value={selectedLocalidade}
          onChange={handleLocalidadeChange}
        >
          <option value="">Selecione uma localidade</option>
          {localidades.map((localidade, index) => (
            <option key={index} value={localidade}>{localidade}</option>
          ))}
        </select>
      </div>

      <button className="escalas-button" onClick={handleSearchEscala}>Buscar Escala</button>

      <div className="escalas-list">
        <h3>Funcionários na Escala:</h3>
        {sortedEscalas.map((funcionario, index) => (
        <li key={index} className="funcionario-card">
          <span className="hora-inicio">{funcionario.horaInicio}</span>
          <span className="nome">{funcionario.nome}</span>
          <div className="icones">
            <FaTrash className="icone" onClick={() => handleDeleteFuncionario(funcionario.id)} />
            <FaBeer className='icone' onClick={() => handleViewConsumo(funcionario)} />
            <FaPlus className="icone" onClick={() => handleFuncionarioBeerClick(funcionario)} />
          </div>
        </li>
      ))}
      </div>
      <button className="add-funcionario-button" onClick={handleAddFuncionario}>Adicionar Funcionário</button>

      {/* Modal para adicionar funcionário */}
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>{selectedLocalidade}</h2>
            <input
              type="text"
              placeholder="Buscar Funcionário"
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
            <ul className="funcionario-list">
              {filteredFuncionarios.map((funcionario, index) => (
                <li key={index} onClick={() => handleFuncionarioClick(funcionario)}>
                  {funcionario.nome}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Modal para adicionar turno */}
      {secondModalOpen && (
        <div className="second-modal">
          <div className="second-modal-content">
            <span className="close-button" onClick={closeSecondModal}>&times;</span>
            <h3>Adicionar Turno</h3>
            <div className="form-group">
              <label>Nome Selecionado:</label>
              <p>{selectedFuncionario?.nome}</p>
            </div>
            <div className="form-group">
              <label>Localidade Selecionada:</label>
              <p>{selectedLocalidade}</p>
            </div>
            <div className="form-group">
              <label>Data Selecionada:</label>
              <p>{data}</p>
            </div>
            <div className="form-group">
              <label>Hora Início:</label>
              <input
                type="time"
                value={horaInicio}
                onChange={(e) => setHoraInicio(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Carga Horária:</label>
              <input
                type="text"
                value={cargaHoraria}
                onChange={(e) => setCargaHoraria(e.target.value)}
              />
            </div>
            <button onClick={handleSave}>Salvar</button>
          </div>
        </div>
      )}

      {/* Novo Modal para adicionar consumo */}
      {thirdModalOpen && (
  <div className="third-modal">
    <div className="third-modal-content">
      <span className="close-button" onClick={closeThirdModal}>
        &times;
      </span>
      <h3>Registrando consumo para {selectedFuncionario?.nome}</h3>
      <input
        type="text"
        placeholder="Buscar Produto"
        value={searchProdutoTerm}
        onChange={handleProductSearchChange}
        className="search-input"
      />
      <div className="produtos-list">
        {produtos
          .filter((produto) =>
            produto.nome.toLowerCase().includes(searchProdutoTerm.toLowerCase())
          )
          .map((produto, index) => {
            const isSelected = consumoInfo?.produtoId === produto._id; // Verificar se o produto está selecionado para quantidade
            return (
              <div
                key={index}
                className={`produto-card ${isSelected ? "produto-card--selected" : ""}`}
                onClick={() =>
                  setTimeout(() => {
                    setConsumoInfo({ produtoId: produto._id });
                  })
                }
              >
                <div className="produto-info">
                  <h4 className="produto-nome">
                    {produto.nome} ({produto.quantidade} {produto.unidade})
                  </h4>
                </div>
                {isSelected && (
                  <div className="quantidade-form">
                    <input
                      type="number"
                      id={`quantidade-${produto._id}`}
                      className="quantidade-input"
                      placeholder="Digite a quantidade"
                      onChange={(e) =>
                        setConsumoInfo({
                          ...consumoInfo,
                          quantidade: e.target.value,
                        })
                      }
                    />
                    <button
                      className="quantidade-button"
                      onClick={() => {
                        handleProductClick(produto, consumoInfo.quantidade);
                        setConsumoInfo(null); // Resetar o estado após salvar
                      }}
                    >
                      Registrar
                    </button>
                  </div>
                )}
              </div>
            );
          })}
      </div>

    </div>
  </div>
)}


{fourthModalOpen && (
  <div className="third-modal">
    <div className="third-modal-content">
      <span className="close-button" onClick={() => setFourthModalOpen(false)}>&times;</span>
      <h3>Consumo de {selectedFuncionario?.nome}</h3>
      <ul>
        {Array.isArray(consumoInfo) ? (
          consumoInfo.map((item, index) => (
            <li key={index}>
              <h4>{item.recorrencia}x {item.nome} {item.quantidade}</h4>
            </li>
          ))
        ) : (
          <li>Nenhum consumo encontrado.</li>
        )}
      </ul>
    </div>
  </div>
)}
    </div>
  );
}

export default EscalasComponent;
