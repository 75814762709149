import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './EscalasComponent.css';

const Escalas = () => {
  const [locais, setLocais] = useState([]);
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [localSelecionado, setLocalSelecionado] = useState('');
  const [escalas, setEscalas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState("");

  useEffect(() => {
    axios.get('https://bugio.app:3000/bugio/cardapio/listarlocais')
      .then(response => setLocais(response.data))
      .catch(error => console.error('Erro ao buscar locais:', error));
  }, []);

  const handlePesquisar = () => {
    if (dataInicio && dataFim && localSelecionado) {
      axios.get('https://bugio.app:3000/escalas/por-intervalo', {
        params: {
          dataInicio,
          dataFim,
          local: localSelecionado,
        },
      })
      .then(response => {
        const escalasArray = Object.keys(response.data).map(data => ({
          data,
          escalas: response.data[data],
        }));

        setEscalas(escalasArray);
        setShowModal(true);
      })
      .catch(error => console.error('Erro ao buscar escalas:', error));
    } else {
      alert('Por favor, preencha todos os campos!');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString + 'T00:00:00');
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('pt-BR', options);
  };

  const formatTimeRange = (horaInicio, cargaHoraria) => {
    const horaInicioParts = horaInicio.split(':');
    const horaInicioHour = parseInt(horaInicioParts[0], 10);
    const horaInicioMinute = parseInt(horaInicioParts[1], 10);
    const cargaHorariaHours = parseInt(cargaHoraria, 10);

    let horaFimHour = horaInicioHour + cargaHorariaHours;
    let horaFimMinute = horaInicioMinute;

    if (horaFimHour >= 24) {
      horaFimHour = horaFimHour % 24;
    }

    return `${horaInicioHour}:${horaInicioMinute < 10 ? '0' : ''}${horaInicioMinute} às ${horaFimHour}:${horaFimMinute < 10 ? '0' : ''}${horaFimMinute}`;
  };

  const generateWhatsAppMessage = () => {
    return escalas
      .map((escala) => {
        const dataFormatada = formatDate(escala.data);
  
        // Ordenando as escalas por horaInicio dos usuários
        const escalasFormatadas = escala.escalas
          .map((item) => {
            return item.usuarios
              .sort((a, b) => {
                // Ordena os usuários pela horaInicio
                const horaInicioA = a.horaInicio || "00:00";
                const horaInicioB = b.horaInicio || "00:00";
                return horaInicioA.localeCompare(horaInicioB);
              })
              .map((usuario) => {
                return usuario && usuario.idUsuario
                  ? `${formatTimeRange(usuario.horaInicio, usuario.cargaHoraria)} - ${usuario.idUsuario.nome}`
                  : `Informações do usuário não disponíveis.`;
              })
              .join("\n");
          })
          .join("\n");
  
        return `*${dataFormatada}*\n${escalasFormatadas}`;
      })
      .join("\n\n");
  };
  

  return (
    <div className="escalas-component">
      <h2 className="escalas-title">Visualizar Escalas</h2>

      <div className="escalas-date-picker">
        <label className="escalas-label">Data de Início</label>
        <input
          type="date"
          className="escalas-date-input"
          value={dataInicio}
          onChange={e => setDataInicio(e.target.value)}
        />
      </div>

      <div className="escalas-date-picker">
        <label className="escalas-label">Data de Fim</label>
        <input
          type="date"
          className="escalas-date-input"
          value={dataFim}
          onChange={e => setDataFim(e.target.value)}
        />
      </div>

      <div className="escalas-localidade-picker">
        <label className="escalas-label">Localidade</label>
        <select
          className="escalas-select-input"
          value={localSelecionado}
          onChange={e => setLocalSelecionado(e.target.value)}
        >
          <option value="">Selecione o local</option>
          {locais.map(local => (
            <option key={local.id} value={local.nome}>
              {local.nome}
            </option>
          ))}
        </select>
      </div>

      <button className="escalas-button" onClick={handlePesquisar}>Pesquisar</button>

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        className="modal"
        overlayClassName="overlay"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <span className="close-button" onClick={handleCloseModal}>&times;</span>
          <h2>Escalas {localSelecionado}</h2>
          {escalas.length > 0 ? (
            <div className="escalas-container">
              {escalas.map((escala) => (
                <div key={escala.data} className="escalas-card">
                  <p>{formatDate(escala.data)}</p>
                  {escala.escalas && escala.escalas.length > 0 ? (
                    escala.escalas.map((item) => (
                      <div key={item._id} className="escalas-item">
                        {item.usuarios && item.usuarios.length > 0 ? (
                          item.usuarios
                            .slice()
                            .sort((a, b) => {
                              const horaInicioA = a.horaInicio || "00:00";
                              const horaInicioB = b.horaInicio || "00:00";
                              return horaInicioA.localeCompare(horaInicioB);
                            })
                            .map((usuario) =>
                              usuario && usuario.idUsuario ? (
                                <div key={usuario._id} className="usuario-card">
                                  <p>
                                    {formatTimeRange(usuario.horaInicio, usuario.cargaHoraria)} - {usuario.idUsuario.nome}
                                  </p>
                                </div>
                              ) : (
                                <div key={usuario._id} className="usuario-card">
                                  <p>Informações do usuário não disponíveis.</p>
                                </div>
                              )
                            )
                        ) : (
                          <p>Nenhum usuário encontrado para esta escala.</p>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>Nenhuma escala encontrada para esta data.</p>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p>Nenhuma escala encontrada.</p>
          )}

          {/* Campo para inserir número do WhatsApp */}
          <div className="whatsapp-container">
            <input
              type="text"
              placeholder="Insira seu WhatsApp (DDD + Número)"
              value={whatsappNumber}
              onChange={(e) => setWhatsappNumber(e.target.value.replace(/\D/g, ""))} // Remove caracteres não numéricos
              maxLength={11} // Limita o número a DD + 9 dígitos
              className="whatsapp-input"
            />
            <button
              className="whatsapp-button"
              onClick={() => {
                if (whatsappNumber.length === 11) {
                  const formattedNumber = `55${whatsappNumber}`;
                  window.open(
                    `https://wa.me/${formattedNumber}?text=${encodeURIComponent(
                      generateWhatsAppMessage()
                    )}`,
                    "_blank"
                  );
                } else {
                  alert("Por favor, insira um número válido com DD e 9 dígitos.");
                }
              }}
            >
              Enviar via WhatsApp
            </button>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default Escalas;
