import React, { useState, useEffect } from 'react';
import './WebSocketComponent.css'; // Importe o arquivo de estilo para a notificação
import notificationSound from './notification.wav'; // Importe o som de notificação

const WebSocketComponent = ({ local, handleRefreshPedidos }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Criar uma conexão WebSocket
    const socket = new WebSocket('wss://bugio.app:3000'); // Usando wss para conexão segura

    // Evento que ocorre quando a conexão é aberta
    socket.onopen = () => {
      console.log('Conexão WebSocket aberta');
    };

    // Função para adicionar uma nova notificação
    const addNotification = (newNotification) => {
      setNotifications(prevNotifications => {
        // Remover a primeira notificação se o número de notificações exceder 5
        if (prevNotifications.length >= 5) {
          prevNotifications.shift();
        }
        // Adicionar a nova notificação no final do array
        return [...prevNotifications, newNotification];
      });
      // Reproduzir o som de notificação
      const audio = new Audio(notificationSound);
      audio.play();

       // Chamar a função handleRefreshPedidos com um atraso de 5 segundos
       setTimeout(() => {
        handleRefreshPedidos();
      }, 3000); // Atraso de 5 segundos

      // Configurar um setTimeout para remover a notificação após 30 segundos
      setTimeout(() => {
        setNotifications(prevNotifications => {
          return prevNotifications.filter(notification => notification.id !== newNotification.id);
        });
      }, 30000); // 30 segundos em milissegundos
    };

    // Evento que ocorre quando uma mensagem é recebida do servidor
    socket.onmessage = (event) => {
      const receivedMessage = JSON.parse(event.data);
      console.log('Mensagem recebida:', receivedMessage);

      // Verificar se a mensagem é do tipo "NovoPedido"
      if (receivedMessage.type === 'NovoPedido') {
        // Verificar se o local é igual ao local da props
        if (receivedMessage.pedido.local === local) {
          // Adicionar a notificação à lista de notificações
          const newNotification = {
            id: Math.random(), // Um ID único para cada notificação
            message: 'Novo Pedido!',
            numeroPedido: receivedMessage.pedido.numeroPedido, // Adicionando o número do pedido à notificação
            timestamp: new Date(receivedMessage.pedido.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) // Formatar a hora
          };
          // Adicionar a nova notificação chamando a função addNotification
          addNotification(newNotification);
        }
      }
    };

    // Evento que ocorre quando a conexão é fechada
    socket.onclose = () => {
      console.log('Conexão WebSocket fechada');
    };

    // Retornar uma função de limpeza para fechar a conexão quando o componente é desmontado
    return () => {
      socket.close();
    };
  }, [local, handleRefreshPedidos]);

  const handleNotificationClose = (id) => {
    setNotifications(prevNotifications => {
      return prevNotifications.filter(notification => notification.id !== id);
    });
  };

  return (
    <div className="notification-container">
      {/* Mapear as notificações e renderizar cada uma delas */}
      {notifications.map(notification => (
        <div key={notification.id} onClick={() => handleNotificationClose(notification.id)} className="notification">
          <p className="notification-message">{notification.message}</p>
          <p className="notification-pedido">Pedido #{notification.numeroPedido}</p> {/* Exibir o número do pedido */}
          <p className="notification-timestamp">{notification.timestamp}</p>
        </div>
      ))}
    </div>
  );
};

export default WebSocketComponent;
