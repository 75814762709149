import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Header from './Header'
import Footer from './Footer'

function Home() {
  return (
    <div className="home-page">
      <Header />
      <main className="cards-container">
        <div className="card">
          <h2>Usuários</h2>
          <Link to="/usuarios">
            <button>Ir para Usuários</button>
          </Link>
        </div>
         <div className="card">
          <h2>Eventos</h2>
          <Link to="/eventos">
            <button>Ir para Eventos</button>
          </Link>
        </div> 
        <div className="card">
          <h2>Cardápio</h2>
          <Link to="/cardapio">
            <button>Ir para Cardápio</button>
          </Link>
        </div>
        <div className="card">
          <h2>Pedidos Cozinha</h2>
          <Link to="/cozinha">
            <button>Ir para Cozinha</button>
          </Link>
        </div>
        
        <div className="card">
          <h2>Consumação</h2>
          <Link to="/consumoartistas">
            <button>Ir para Consumação</button>
          </Link>
        </div>
        <div className="card">
          <h2>Notificações</h2>
          <Link to="/notifications">
            <button>Ir para Notificações</button>
          </Link>
        </div>
        <div className="card">
          <h2>Escalas</h2>
          <Link to="/escalas">
            <button>Ir para Escalas</button>
          </Link>
        </div>

        <div className="card">
          <h2>Relatórios</h2>
          <Link to="/relatorios">
            <button>Ir para Relatórios</button>
          </Link>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
