import React, { useState } from 'react';
import axios from 'axios';
import './Usuarios.css'; // Reaproveitando o estilo existente

function RelatorioVendas() {
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleDownloadReport = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setError('');
  
      // Construir a URL com os parâmetros de query
      const url = `https://bugio.app:3000/gerar-relatorio/vendas?dataInicial=${encodeURIComponent(
        startDateTime
      )}&dataFinal=${encodeURIComponent(endDateTime)}`;
  
      // Realiza a requisição para baixar o relatório
      const response = await axios.get(url, {
        responseType: 'blob', // Define o tipo de resposta como arquivo
      });
  
      // Cria um link para o download do arquivo
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'relatorio_vendas.xlsx'); // Nome do arquivo
      document.body.appendChild(link);
      link.click();
      link.remove();
  
      alert('Relatório baixado com sucesso!');
    } catch (error) {
      console.error('Erro ao baixar relatório:', error);
  
      // Verifica se há mensagem de erro no servidor
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('Erro ao baixar o relatório. Por favor, tente novamente mais tarde.');
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="usuarios-page">
      <div className="usuarios-content">
        <div className="usuarios-card">
          <h2>Relatório de Vendas</h2>
          <p>
            Este relatório detalha as vendas realizadas no aplicativo, abrangendo informações
            organizadas por data, hora, produto e local de venda. Ele inclui transações com pagamentos
            realizados via Pix ou cartão.
          </p>
          <form>
            <div className="form-group">
              <label htmlFor="startDateTime">Data/Hora Inicial:</label>
              <input
                type="datetime-local"
                id="startDateTime"
                value={startDateTime}
                onChange={(e) => setStartDateTime(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDateTime">Data/Hora Final:</label>
              <input
                type="datetime-local"
                id="endDateTime"
                value={endDateTime}
                onChange={(e) => setEndDateTime(e.target.value)}
              />
            </div>
            <button onClick={handleDownloadReport} disabled={isLoading}>
              {isLoading ? 'Baixando...' : 'Baixar Relatório'}
            </button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default RelatorioVendas;
