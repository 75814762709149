import React, { useState, useEffect } from 'react';
import './ConjuntoFichas.css';

function ConjuntoFichas({ atualizarConjuntos }) {
  const [descricao, setDescricao] = useState('');
  const [locais, setLocais] = useState([]);
  const [quantidade, setQuantidade] = useState('');
  const [dataHoraValidade, setDataHoraValidade] = useState('');
  const [opcoesLocais, setOpcoesLocais] = useState([]);

  useEffect(() => {
    const fetchOpcoesLocais = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/cardapio/listarlocais');
        if (!response.ok) throw new Error('Erro ao buscar as opções de locais');
        const data = await response.json();
        const nomesLocais = data.map((local) => local.nome);
        setOpcoesLocais(nomesLocais);
      } catch (error) {
        console.error(error);
      }
    };
    fetchOpcoesLocais();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      descricao,
      locais,
      quantidade: parseInt(quantidade, 10),
      validade: dataHoraValidade,
    };

    try {
      const response = await fetch('https://bugio.app:3000/bugio/gerarconjuntofichas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      alert('Conjunto de fichas gerado com sucesso!');
      resetFormularioCadastro();
      atualizarConjuntos(); // Atualiza os conjuntos na página principal
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const resetFormularioCadastro = () => {
    setDescricao('');
    setLocais([]);
    setQuantidade('');
    setDataHoraValidade('');
  };

  return (
    <div className="conjunto-fichas-cadastro-container">
      <h2 className="conjunto-fichas-cadastro-title">Cadastro de Conjunto de Fichas</h2>
      <form onSubmit={handleSubmit} className="conjunto-fichas-cadastro-form">
        <input
          className="conjunto-fichas-cadastro-input"
          placeholder="Descrição"
          type="text"
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
        />
        <select
          className="conjunto-fichas-cadastro-select"
          multiple
          value={locais}
          onChange={(e) => setLocais(Array.from(e.target.selectedOptions, (option) => option.value))}
        >
          <option value="">Selecione os locais</option>
          {opcoesLocais.map((local, index) => (
            <option key={index} value={local}>{local}</option>
          ))}
        </select>
        <input
          className="conjunto-fichas-cadastro-input"
          placeholder="Quantidade"
          type="number"
          min="1"
          value={quantidade}
          onChange={(e) => setQuantidade(e.target.value)}
        />
        <input
          className="conjunto-fichas-cadastro-input"
          placeholder="Data e Hora de Validade"
          type="datetime-local"
          value={dataHoraValidade}
          onChange={(e) => setDataHoraValidade(e.target.value)}
        />
        <button className="conjunto-fichas-cadastro-button" type="submit">Cadastrar</button>
      </form>
    </div>
  );
}

export default ConjuntoFichas;
