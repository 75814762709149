import React from 'react';
import './Usuarios.css';
import Header from './Header'
import Footer from './Footer'
import RelatorioVendas from './RelatorioVendas'
import RelatorioConsumacao from './RelatorioConsumacao'
import RelatorioExtrato from './RelatorioExtrato'
function Notifications() {
  return (
    <div className="usuarios-page">
      <Header />
      <div className="usuarios-content">
        <RelatorioExtrato />
        <RelatorioVendas />
        <RelatorioConsumacao />
        
      </div>
      <Footer />
    </div>
  );
}

export default Notifications;